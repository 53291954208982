import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Layout from '../../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

import ZoominfoFields from '../../components/partials/Zoominfo-fields'
import callZoominfo from '../../helpers/zoomInfo'

import { getClientId } from '../../helpers/getClientId'
import { getCookieByName } from '../../helpers/getCookieByName'

const newsletter = () => {
  const data = useStaticQuery(graphql`
    query NewsletterSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Newsletter" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  useEffect(() => {
    // Zoominfo Snippet
    callZoominfo('uoOiaHNN8N3QdXczOrJm')

    // Getting clientId
    const interval = setInterval(function () {
      if (getClientId()) {
        let gaClientId = getClientId()
        document.querySelector<HTMLInputElement>('#ga_client_id').value =
          gaClientId
        clearInterval(interval)
      }
    }, 1000)
  }, [])

  const setEmailvalue = (e) => {
    let lastName = document.querySelector<HTMLInputElement>('#lastName')
    let firstName = document.querySelector<HTMLInputElement>('#firstName')
    firstName.value = e.target.value
    lastName.value = e.target.value
  }

  const onSubmit = () => {
    document.querySelector<HTMLInputElement>('#splunk_id').value =
      getCookieByName('mintjs%3Auuid')
  }

  return (
    <Layout newsletter={false}>
      <HeaderSEO pageSEO={pageSEO} />
      <StaticImage
        className="home-fixed-top"
        src="../../resources/images/homepage/hero_lens.svg"
        placeholder="blurred"
        alt="Hero Lenses"
      />
      <section className="mt-5 pt-5 pb-4">
        <div className="container-1 ipad-margin-top">
          <div className="d-flex flex-wrap mt-3">
            <div className="col-md-6">
              <h1 className=" mobile-margin-top">Join our Newsletter</h1>
              <p></p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container-1">
          <div className="d-flex flex-wrap">
            <div className="col-sm-10 col-md-6 offset-md-0 offset-1 col-10 shadow p-5 bg-white">
              <h2 className="h3-like">Stay tuned!</h2>
              <p>
                {' '}
                If you would like to receive Lenses content in your inbox,
                please opt-in using this form.
              </p>
              {/* ATTENTION! Form ID needs to be matching Zoominfo settings as described here https://landoop.atlassian.net/wiki/spaces/MAR/pages/2910453761/Zoominfo+Website+integration */}
              <form
                action="https://go.pardot.com/l/877242/2020-08-04/4fkdbk"
                id="zoominfo-all-fields-form"
                method="POST"
                onSubmit={onSubmit}
              >
                <ZoominfoFields
                  includeCompany={true}
                  includePhone={true}
                  includeJobTitle={true}
                />
                <input
                  type="hidden"
                  maxLength={120}
                  name="Last Name"
                  id="lastName"
                />
                <input
                  type="hidden"
                  maxLength={120}
                  name="First Name"
                  id="firstName"
                />
                <input
                  type="email"
                  maxLength={100}
                  name="Email"
                  autoComplete="new"
                  className="form-control py-4 w-75 rounded-0 text-dark"
                  placeholder="Email"
                  id="email"
                  onKeyUp={(e) => setEmailvalue(e)}
                  required
                />

                <input
                  name="GA Client ID"
                  type="hidden"
                  className="d-none"
                  id="ga_client_id"
                />

                <input
                  type="hidden"
                  name="Splunk ID"
                  id="splunk_id"
                  className="d-none"
                />

                <input
                  className="mt-2 btn btn-second rounded-0 f-14 fw-600 px-4 py-2"
                  type="submit"
                  value="Join now!"
                />

                <br />
                <br />
                <small>
                  By sending this you agree to our{' '}
                  <a className="link-text" href="/legals/terms-and-conditions/">
                    Terms and Conditions
                  </a>{' '}
                  and{' '}
                  <a className="link-text" href="/legals/policy/">
                    Privacy Policy
                  </a>
                </small>
              </form>
            </div>

            <div className="col-sm-12 col-md-6 p-5">
              <StaticImage
                className="img-fluid mx-auto d-block"
                src="../../resources/images/vectors/undraw_newspaper_k72w.svg"
                placeholder="tracedSVG"
                alt="Lenses.io Newsletter"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default newsletter
