export default function callZoominfo(localformId) {
    // Zoominfo Snippet
    try {
        window._zi = { formId: localformId }
        var zi = document.createElement('script')
        zi.type = 'text/javascript'
        zi.async = true
        zi.src = 'https://ws-assets.zoominfo.com/formcomplete.js'
        var s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(zi, s)
    } catch (error) {
        console.log(error)
    }
}

export function fillZoominfoFields() {
    try {
        sessionStorage.setItem(
            'zoominfoIndustryHierarchicalCategory',
            document.querySelector('input[name="Zoominfo Industry Hierarchical Category"]').value,
        )
        sessionStorage.setItem(
            'zoominfoCompanyCity',
            document.querySelector('input[name="Zoominfo Company City"]').value,
        )
        sessionStorage.setItem(
            'zoominfoCompanyStreet',
            document.querySelector('input[name="Zoominfo Company Street"]').value,
        )
        sessionStorage.setItem(
            'zoominfoCompanyRevenue',
            document.querySelector('input[name="Zoominfo Company Revenue"]').value,
        )
        sessionStorage.setItem(
            'zoominfoWebsite',
            document.querySelector('input[name="Zoominfo Website"]').value,
        )
        sessionStorage.setItem(
            'zoomInfοManagementLevel',
            document.querySelector('input[name="ZoomInfο Management Level"]').value,
        )
        sessionStorage.setItem(
            'zoomInfoJobFunction',
            document.querySelector('input[name="ZoomInfo Job Function"]').value,
        )
        sessionStorage.setItem(
            'zoomInfoCompanyID',
            document.querySelector('input[name="ZoomInfo Company ID"]').value,
        )
        sessionStorage.setItem(
            'zoomInfoContactID',
            document.querySelector('input[name="ZoomInfo Contact ID"]').value,
        )
        sessionStorage.setItem(
            'ZoominfoMailingPostal',
            document.querySelector('input[name="Zoominfo Mailing Postal"]').value,
        )
        sessionStorage.setItem(
            'ZoominfoMailingState',
            document.querySelector('input[name="Zoominfo Mailing State"]').value,
        )
        sessionStorage.setItem(
            'ZoominfoCompanyLinkedInURL',
            document.querySelector('input[name="Zoominfo Company LinkedIn URL"]').value,
        )
        sessionStorage.setItem(
            'ZoominfoCompanySize',
            document.querySelector('input[name="Zoominfo Company Size"]').value,
        )
        sessionStorage.setItem(
            'ZoominfoIndustry',
            document.querySelector('input[name="Zoominfo Industry"]').value,
        )
    } catch (error) {
        console.log(error)
    }
}
