import React from 'react'
import PropTypes from 'prop-types'

const zoominfoFields = ({ includeCompany, includePhone, includeJobTitle }) => {
    return (
        <div className='d-none'>
            {includeCompany && <input type='text' name='Company' />}
            {includePhone && <input type='text' name='Phone' />}
            {includeJobTitle && <input type='text' name='Job Title' />}
            <input type='text' name='Zoominfo Industry Hierarchical Category' />
            <input type='text' name='Zoominfo Company City' />
            <input type='text' name='Zoominfo Company Street' />
            <input type='text' name='Zoominfo Company Revenue' />
            <input type='text' name='Zoominfo Website' />
            <input type='text' name='ZoomInfο Management Level' />
            <input type='text' name='ZoomInfo Job Function' />
            <input type='text' name='ZoomInfo Company ID' />
            <input type='text' name='ZoomInfo Contact ID' />
            <input type='text' name='Zoominfo Mailing Postal' />
            <input type='text' name='Zoominfo Mailing State' />
            <input type='text' name='Zoominfo Company LinkedIn URL' />
            <input type='text' name='Zoominfo Company Size' />
            <input type='text' name='Zoominfo Industry' />
        </div>
    )
}

zoominfoFields.defaultProps = {
    includeCompany: false,
    includePhone: false,
    includeJobTitle: false,
}

export default zoominfoFields

zoominfoFields.propTypes = {
    includeCompany: PropTypes.bool,
    includePhone: PropTypes.bool,
    includeJobTitle: PropTypes.bool,
}
